import { useEffect, useState } from "react";
import "../css/contacts.css";
export default function Contacts(params) {
    const [first, setfirst] = useState(true);


    useEffect(() => {
        if (first) {
            AddLibrary("https://api-maps.yandex.ru/2.1/?apikey=92d2de59-e610-4dd0-afb7-3d4d311cd139&lang=ru_RU")

            setTimeout(() => {
            AddLibrary("js/contact.js")
                
            }, 1000);

            setfirst(false)
        }
    }, [first])
    


    return (
        <main className="main">
            <section className="contacts">
                <div className="contacts__wrapper">
                    <h1 className="contacts__title">
                        <span className="animated">
                            Контакты
                        </span>
                    </h1>
                    <ul
                        className="contacts__list"
                        itemScope
                        itemType="https://schema.org/Organization"
                    >
                        <li className="contacts__item contacts__item_surova animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    г. Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    Проспект Врача Сурова 2а
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_prom animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    г. Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    ул. Промышленная 59б
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_tupoleva animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    г. Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    пр-т. Туполева 15
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    посёлок городского типа
                                    Чердаклы
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    ул. Советская, 34a
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    г. Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    ул. Рябикова 106а
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                    г. Димитровград
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    ул. Юнг Северного Флота
                                    8
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                   г.Сызрань
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    ул. Челюскинцев 18, на парковке ТЦ Магнит
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru​"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                   г.Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    Ул.Луначарского 2б
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                   Республика Татарстан (Татарстан)
                                   Спасский район, Болгар 
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    Рыночная улица, 1Г
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                        <li className="contacts__item contacts__item_cherdakli animated">
                            <address className="contacts__address">
                                <span
                                    className="contacts__address-text"
                                    itemProp="addressLocality"
                                >
                                  г. Ульяновск
                                </span>{" "}
                                <span
                                    className="contacts__address-text"
                                    itemProp="streetAddress"
                                >
                                    проспект Созидателей
                                </span>
                            </address>
                            <a
                                href="mailto:salyt73@mail.ru"
                                className="contacts__link contacts__link_mail"
                                itemProp="email"
                            >
                                salyt73@mail.ru
                            </a>
                        </li>
                    </ul>
                </div>
                <div
                    className="contacts__map"
                    id="contacts__map"
                ></div>
                <div className="contacts__more-wrapper">
                    <a
                        href="contacts.php"
                        className="contacts__more"
                    >
                        Показать на карте
                    </a>
                </div>
            </section>
            

        </main>
    );
}


export function AddLibrary(urlOfTheLibrary) {
    const script = document.createElement("script");
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script);
}
